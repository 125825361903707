<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">담당자 조회</h2>
      </div>
      <div class="body_section">
        <DirectorListFilterBar
          :statusList="statusList"
          :keywordDatas="KEYWORD_DATAS"
          :defaultKeywordSelectedId="defaultKeywordSelectedId"
          :defaultKeywordInputText="defaultKeywordInputText"
          :defaultStateId="defaultStateId"
          @onClickSearch="onClickSearch"
        />

        <TableHead>
          <template v-slot:headLeft>
            <button
              v-if="isShowAccessBtn(menuId.SYSTEM_DIRECTOR_WRITE)"
              type="button"
              class="btn_tertiary btn_medium"
              @click="onClickAdd"
            >
              <span class="ico_purchase ico_cross_w" />신규등록
            </button>
          </template>
          <template v-slot:headRight>
            <SelectBoxPageRange
              :currentMaxRowCount.sync="size"
              @update:currentMaxRowCount="onChangeRowCount"
            />
          </template>
        </TableHead>
        <ListPage
          :list="dataList"
          :currentPageIndex="currentPageIndex"
          :totalPageCount="totalPageCount"
          :maxRowCount="size"
          :maxPaginationCount="maxPaginationCount"
          @onChangePage="onChangePage"
        >
          <template v-slot:colgroup>
            <col style="width: 120px" />
            <col style="width: 129px" />
            <col style="width: 120px" />
            <col style="width: 136px" />
            <col />
            <col style="width: 135px" />
            <col style="width: 135px" />
            <col style="width: 120px" />
            <col style="width: 120px" />
            <template v-if="isDefaultOwner">
              <col style="width: 100px" />
            </template>
          </template>
          <template v-slot:tr>
            <th>계정 상태</th>
            <th>담당자명</th>
            <th>직급</th>
            <th>담당업무</th>
            <th>이메일 주소</th>
            <th>직통번호</th>
            <th>핸드폰번호</th>
            <th>계정 등록일</th>
            <th>최근 접속일</th>
            <th v-if="isDefaultOwner">기본 <br />구매담당자여부</th>
          </template>
          <template v-slot:row="slotProps">
            <DirectorListLine
              :rowData="slotProps.rowData"
              :isDefaultOwner="isDefaultOwner"
              @onChangeDefaultAdmin="onChangeDefaultAdmin"
            />
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="9" class="td_empty">담당자가 없습니다</td>
            </tr>
          </template>
        </ListPage>
      </div>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import DirectorListFilterBar from "@/components/admin/system/director/list/DirectorListFilterBar";
import TableHead from "@/components/shared/TableHead";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";
import ListPage from "@/components/shared/ListPage";
import DirectorListLine from "@/components/admin/system/director/list/DirectorListLine";

import { board } from "@/constants/options";

import { GET_MANAGER_STATUS_LIST } from "@/store/modules/manager/action";
import { mapState } from "vuex";

import PageMixin from "@/mixins/PageMixin";
import ListPageMixin from "@/mixins/ListPageMixin";

import { ALERT_ACTION } from "@/store/modules/alert/action";

import ApiService from "@/services/ApiService";

export default {
  components: {
    PageWithLayout,
    DirectorListFilterBar,
    TableHead,
    SelectBoxPageRange,
    ListPage,
    DirectorListLine,
  },
  mixins: [PageMixin, ListPageMixin],
  data() {
    return {
      API_PATH: this.$apiPath.MANAGER,
      KEYWORD_DATAS: [
        { id: "name", text: "담당자명" },
        { id: "email", text: "이메일" },
        { id: "tel", text: "연락처" },
      ],
      defaultKeywordSelectedId: "name",
      isDefaultOwner: false,
    };
  },
  async created() {
    const params = this.checkUrlQuery();

    this.$store.dispatch(GET_MANAGER_STATUS_LIST).then(() => {
      this.getDataInit(params);
    });
  },
  computed: {
    ...mapState({
      statusList: (state) => state.manager.statusList,
    }),
  },
  methods: {
    // 주소 기준으로 현재 페이지에 필요한 params 가져온다.
    // 주소 기준으로 필터바 초기값을 셋팅한다.
    checkUrlQuery() {
      const { query } = this.$route;

      const objPaging = this.checkQueryPaging(query);

      const objKeyword = this.checkQueryKeywords(query, this.KEYWORD_DATAS);

      // const objDates = this.checkQueryDates(query, 'regDate1', 'regDate2');

      const objStatus = this.checkQueryStatus(query);

      // const objCateNum = this.checkQueryCateNum(query);

      return Object.assign({}, objPaging, objKeyword, objStatus);
    },
    getFilterbarParams(objData) {
      const {
        keywordSelectedId,
        keywordInputText,
        // startDt,
        // endDt,
        status,
        // category0, category1, category2,
      } = objData;

      let params = {};

      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }

      // if (startDt && endDt) {
      //   params['regDate1'] = startDt;
      //   params['regDate2'] = endDt;
      // }

      if (status) {
        params.status = status;
      }

      // const cateNum = category2 || category1 || category0;
      // if( cateNum ) {
      //   params.cateNum = cateNum;
      // }

      return params;
    },
    onClickAdd() {
      this.$router.push({
        path: this.$routerPath.SYSTEM_DIRECTOR_WRITE,
      });
    },
    onChangeDefaultAdmin(adminData) {
      const params = {
        text: "기본 구매담당자를 수정하시겠습니까?",
        onClickY: () => {
          this.submitDefaultAdmin(adminData);
        },
      };

      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
    async submitDefaultAdmin(adminData) {
      const path = `${this.$apiPath.MANAGER_OWNER}/${adminData.managerNum}`;
      const result = await ApiService.shared.putData(path);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      if (data) {
        this.alert("기본 구매담당자가 변경되었습니다.");
        const params = this.checkUrlQuery();
        this.getDataInit(params);
      }
    },
  },
};
</script>
