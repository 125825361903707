<template>
  <ListRow>
    <td>
      <span :class="['badge_state', stateType]">{{ statusText }}</span>
    </td>
    <td>
      <router-link :to="toPath" class="link_subject align_center">
        {{ rowData.name }} ({{ rowData.loginId }})
      </router-link>
    </td>
    <td>{{ rowData.jobgrade }}</td>
    <td>{{ rowData.job }}</td>
    <td class="td_ellip align_left" :data-ellip="rowData.email">
      {{ rowData.email }}
    </td>
    <td>{{ rowData.tel }}</td>
    <td>{{ rowData.mobile }}</td>
    <td>{{ regDate }}</td>
    <td>{{ lastDate }}</td>
    <td v-if="isDefaultOwner">
      <RadioItem
        v-if="rowData.role === 'SUPER' || rowData.role === 'ADMIN'"
        :id="rowData.loginId"
        :name="'DefaultOwner'"
        :selectedId="computedSelectedId"
        @onChange="onChangeDefaultAdmin"
      />
    </td>
  </ListRow>
</template>

<script>
import RadioItem from "@/components/common/radio/RadioItem.vue";
import ListRow from "@/components/shared/ListRow";
import ListLineMixin from "@/mixins/ListLineMixin";
import { makePathWithQuery } from "@/utils/urlUtils";

export default {
  name: "DirectorListLine",
  components: {
    RadioItem,
    ListRow,
  },
  mixins: [ListLineMixin],
  props: {
    isCloseAnnounce: Boolean,
    isDefaultOwner: Boolean,
  },
  computed: {
    toPath() {
      const { managerNum } = this.rowData;
      const path = makePathWithQuery(
        this.$routerPath.SYSTEM_DIRECTOR_MODIFY,
        managerNum,
        this.$route.query,
      );
      return path;
    },
    statusText() {
      const { status } = this.rowData;
      switch (status) {
        case "D":
          return "휴면";
        case "A":
          return "활성";
        case "I":
          return "비활성";
      }
      return "";
    },
    stateType() {
      const { status } = this.rowData;
      switch (status) {
        case "D":
          return "disabled";
        case "A":
          return "on";
        case "I":
          return "";
      }
      return "";
    },
    computedSelectedId() {
      let ret = null;
      if (this.rowData.defaultOwner === "Y") {
        ret = this.rowData.loginId;
      }
      return ret;
    },
  },
  methods: {
    onChangeDefaultAdmin() {
      this.$emit("onChangeDefaultAdmin", this.rowData);
    },
  },
};
</script>
